import React, { useState, useRef, useEffect, useId } from 'react'
import {
  StyleSheet,
  ScrollView,
  Image,
  TextInput,
  Text,
  View,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from 'react-native'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'
import { MEDICATION_FOLLOW_UP_CONST } from 'constants/values'
import { ShopInfo } from 'core/domain/shopInfo'
import { clearMessagesResult } from 'core/modules/medicationFollowUpMessagesResult'
import { Usecases } from 'core'
import { useAppDispatch, useAppSelector } from 'lib/hooks'
import { GetMessageApiPayload, SendMessageApiPayload } from 'core/domain/medicationFollowUpMessages'
import endMessageIcon from 'assets/images/end_message_icon.png'
import { formatDateTime } from 'utils/dateFormatters'
import { SAFE_AREA } from 'constants/patterns'

const styles = StyleSheet.create({
  background: {
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  header: {
    height: 88,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: COLORS.BORDER,
  },
  headerImageSection: {
    width: '100%',
    alignContent: 'center',
    marginTop: SPACES.MP1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerTextSection: {
    marginTop: SPACES.MP1,
  },
  headerText: {
    color: COLORS.CPC,
    textAlign: 'center',
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    fontWeight: 'bold',
  },
  banner: {
    width: 48,
    height: 48,
    borderRadius: SPACES.MP1,
  },
  container: {
    height: 'calc(100% - 88px)',
    display: 'flex',
    backgroundColor: '#FFFFFF',
  },
  messageArea: {
    flexDirection: 'row',
    marginTop: 4,
    marginBottom: 4,
    paddingVertical: SPACES.MP1,
    paddingHorizontal: SPACES.MP3,
    alignItems: 'flex-end',
  },
  sendBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  messageBlock: {
    flexDirection: 'column',
    flexShrink: 1,
    backgroundColor: COLORS.BACKGROUND_PRIMARY,
  },
  messageBox: {
    maxWidth: 200,
    padding: SPACES.MP1,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
  },
  dateBox: {
    marginLeft: SPACES.MP1,
    marginRight: SPACES.MP1,
  },
  chatSubContentsFontSize: {
    fontSize: 10,
    lineHeight: 15,
  },
  selfMessageBox: {
    backgroundColor: '#C5D3D1',
  },
  headerDateBox: {
    alignItems: 'center',
    paddingVertical: 4,
  },
  headerDateText: {
    fontSize: FONTS.SMALL_FOOTNOTE.size,
    lineHeight: FONTS.SMALL_FOOTNOTE.leading,
  },
  endMessageBlock: {
    width: '100%',
    height: 30,
    borderRadius: 24,
    backgroundColor: '#E5E5E5',
    display: 'flex',
    alignItems: 'center',
  },
  endMessageBox: {
    flexDirection: 'row',
    paddingTop: SPACES.MP1,
    paddingBottom: SPACES.MP1,
  },
  endMessageIcon: {
    marginRight: SPACES.MP1,
    width: 14,
    height: 14,
  },
  endMessageText: {
    fontSize: FONTS.CAPTION.size,
    lineHeight: FONTS.CAPTION.leading,
  },
  messageSendBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: SPACES.MP2,
    paddingVertical: SPACES.MP1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px -4px 2px rgba(0, 0, 0, 0.05)',
  },
  messageSendText: {
    outlineColor: COLORS.SECONDARY,
    borderRadius: 20,
    color: COLORS.TEXT,
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    backgroundColor: '#FFFFFF',
    padding: 12,
    width: '85%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.BORDER,
  },
  messageSendButton: {
    color: COLORS.CPC,
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    fontWeight: 'bold',
    alignContent: 'center',
    marginRight: SPACES.MP1,
  },
})

/**
 * Interface
 */
interface Props {
  shop: ShopInfo
}

export default function MedicationFollowUp(props: Props) {
  const scrollViewId = useId()
  const { shop } = props
  const dispatch = useAppDispatch()
  const [messageText, setMessageText] = useState('')
  const [isloading, setIsLoading] = useState(false)
  const currentHeight = useRef(0)
  const isPostMessage = useRef(true)
  let currentDate = ''
  const scrollViewRef = useRef<ScrollView>(null)
  const body = document.querySelector('body')
  if (body) {
    body.style.overflow = 'hidden'
  }
  const [height, setHeight] = useState(47)

  useEffect(() => {
    // メッセージ履歴を削除しメッセージを取得
    dispatch(clearMessagesResult())

    const getMessagesPayload: GetMessageApiPayload = {
      shopId: shop.id,
      perPage: MEDICATION_FOLLOW_UP_CONST.GET_MESSAGES_LIMIT,
      id: '',
      sendAt: '',
    }

    // メッセージ取得API
    dispatch(Usecases.getMedicationFollowUpMessages.sendGetMessages(getMessagesPayload))
    dispatch(Usecases.putMedicationFollowUpMessagesRead.sendPutMessagesRead(shop.id))
  }, [dispatch, shop.id])

  const messageList = useAppSelector(state => state.medicationFollowUpMessages.messageList)
  const isCompletedMessage = useAppSelector(state => state.medicationFollowUpMessages.isCompleted)
  const exclusiveStartKey = useAppSelector(state => state.medicationFollowUpMessages.exclusiveStartKey)

  useEffect(() => {
    const ref = document.getElementById(scrollViewId)
    const scrollHeight = ref?.scrollHeight !== undefined ? ref?.scrollHeight : 0
    if (isPostMessage.current) {
      scrollViewRef.current?.scrollToEnd({ animated: false })
    } else {
      scrollViewRef.current?.scrollTo({ y: scrollHeight - currentHeight.current, animated: false })
    }
    isPostMessage.current = false
    setIsLoading(false)
  }, [messageList.length, scrollViewId])

  function getMessageBlockHeight() {
    return 'calc(100% - ' + MEDICATION_FOLLOW_UP_CONST.NEW_MASSAGE_MIN_HEIGHT + 'px - 16px)'
  }

  const isExclusiveStartKey = (): boolean => {
    return !!(exclusiveStartKey?.id && exclusiveStartKey?.sendAt)
  }

  const checkScroll = (contentOffset: { x: number; y: number }, contentHeight: number) => {
    // スクロールが0(一番上)で尚且つまだ未取得のメッセージがある場合は過去メッセージ取得
    if (contentOffset.y !== 0 || messageList.length === 0 || !isExclusiveStartKey() || isloading) return
    setIsLoading(true)
    currentHeight.current = contentHeight
    const payload: GetMessageApiPayload = {
      shopId: shop.id,
      perPage: MEDICATION_FOLLOW_UP_CONST.GET_MESSAGES_LIMIT,
      id: exclusiveStartKey?.id,
      sendAt: exclusiveStartKey?.sendAt,
    }
    dispatch(Usecases.getMedicationFollowUpMessages.sendGetMessages(payload))
  }

  // YYYY/MM/DD HH:ii:ssのYYYY/MM/DDを比較し日付が違うなら中央に日付を表示
  const getCurrentDate = (sendAt: string) => {
    const dt = formatDateTime(new Date(sendAt)).slice(0, 10)
    if (currentDate === dt) return
    currentDate = dt
    return (
      <View style={styles.headerDateBox}>
        <Text style={styles.headerDateText}>{dt}</Text>
      </View>
    )
  }

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <View style={styles.header}>
        <View style={styles.headerImageSection}>
          <Image style={styles.banner} resizeMode={'cover'} source={{ uri: props.shop.imageUrl }} />
        </View>
        <View style={styles.headerTextSection}>
          <Text style={styles.headerText}>{props.shop.name}</Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={[styles.messageBlock, { height: getMessageBlockHeight() }]}>
          <ScrollView
            id={scrollViewId}
            ref={scrollViewRef}
            onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
              checkScroll(event.nativeEvent.contentOffset, event.nativeEvent.contentSize.height)
            }}
            scrollEventThrottle={16}>
            {messageList?.map((item, index) => (
              <>
                {getCurrentDate(item.sendAt)}
                {item.isShop && (
                  <View style={styles.messageArea} key={index}>
                    <View style={styles.messageBox}>
                      <Text>{item.content}</Text>
                    </View>
                    <View style={styles.dateBox}>
                      <Text style={styles.chatSubContentsFontSize}>
                        {formatDateTime(new Date(item.sendAt)).slice(-5)}
                      </Text>
                    </View>
                  </View>
                )}
                {!item.isShop && (
                  <View style={[styles.messageArea, styles.sendBox]} key={index}>
                    <View style={styles.dateBox}>
                      <Text style={styles.chatSubContentsFontSize}>
                        {formatDateTime(new Date(item.sendAt)).slice(-5)}
                      </Text>
                    </View>
                    <View style={[styles.messageBox, styles.selfMessageBox]}>
                      <Text>{item.content}</Text>
                    </View>
                  </View>
                )}
              </>
            ))}
          </ScrollView>
        </View>
        {!isCompletedMessage && (
          <View style={styles.messageSendBox}>
            <TextInput
              style={[styles.messageSendText, { height: height }]}
              editable={!isCompletedMessage}
              numberOfLines={4}
              maxLength={MEDICATION_FOLLOW_UP_CONST.MESSAGE_TEXT_AREA_LIMIT}
              placeholder={'メッセージを書く'}
              placeholderTextColor={'#9AA1AE'}
              multiline={true}
              value={messageText}
              onChangeText={(value: string) => {
                setMessageText(value)
                value === '' && setHeight(MEDICATION_FOLLOW_UP_CONST.NEW_MASSAGE_MIN_HEIGHT)
              }}
              onContentSizeChange={event => {
                if (event.nativeEvent.contentSize.height <= MEDICATION_FOLLOW_UP_CONST.NEW_MESSAGE_AUTO_SCROLL_HEIGHT) {
                  setHeight(event.nativeEvent.contentSize.height + 2)
                } else {
                  setHeight(MEDICATION_FOLLOW_UP_CONST.NEW_MESSAGE_AUTO_SCROLL_HEIGHT)
                }
              }}
            />
            <Text
              style={styles.messageSendButton}
              onPress={async () => {
                if (!shop) return
                if (messageText.length === 0 || messageText.trim() === '') {
                  setMessageText('')
                  setHeight(MEDICATION_FOLLOW_UP_CONST.NEW_MASSAGE_MIN_HEIGHT)
                  return
                }
                // message送信API
                const payload: SendMessageApiPayload = {
                  shopId: shop.id,
                  content: messageText,
                }
                dispatch(Usecases.sendMedicationFollowUpMessage.sendPostMessages(payload))
                isPostMessage.current = true
                setMessageText('')
                setHeight(MEDICATION_FOLLOW_UP_CONST.NEW_MASSAGE_MIN_HEIGHT)
              }}>
              送信
            </Text>
          </View>
        )}
        {isCompletedMessage && (
          <View style={styles.endMessageBlock}>
            <View style={styles.endMessageBox}>
              <Image style={styles.endMessageIcon} resizeMode={'contain'} source={{ uri: endMessageIcon }} />
              <Text style={styles.endMessageText}>{'ご回答ありがとうございました。確認が完了しました。'}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}
